import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Form from "./pages/Form";
import Submitted from "./pages/Submitted";
import Review from "./pages/Review";
function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Form />} />
      <Route path="submitted" element={<Submitted />} />
      <Route path="review" element={<Review />} />
    </Routes>
  </BrowserRouter>
  );
}

export default App;
