import { useState, useEffect } from 'react';
import '../App.css';
import { useNavigate } from "react-router-dom";

function Form() {
  const [question, setQuestion] = useState([]);
  const [error, setError] = useState(false);
  const [readOnly, setReadOnly] = useState(false);
  const [textAnswer, setTextAnswer] = useState([]);
  const [radioAnswer, setRadioAnswer] = useState([]);
  const [checkAnswer, setCheckAnswer] = useState([]);
  const [answer, setAnswer] = useState([]);
  //const [questionsCount, setQuestionsCount] = useState([]);
  const hhUrl = process.env.REACT_APP_API_URL;
  const hhTokenURL = process.env.REACT_APP_TOKEN_API_URL;
  const clientId = process.env.REACT_APP_HH_CLIENT_ID;
  const clientSecret = process.env.REACT_APP_HH_CLIENT_SECRET;
  const navigate = useNavigate();

  useEffect(() => {
    const loadPost = async () => {
  
      // Till the data is fetch using API 
      // the Loading page will show.

      let shortCode = "";
      if (typeof window !== "undefined") {
        var url_string = window.location.href;
        var url = new URL(url_string);
        shortCode = url.searchParams.get("sc");
      }
      
      // Await make wait until that 
      // promise settles and return its result

      await fetch(`${hhTokenURL}/connect/token`, {
      method: 'POST',
      body: new URLSearchParams({
        grant_type: 'client_credentials',
        client_id: clientId,
        client_secret: clientSecret,
      }),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
         'mode': 'no-cors',
         'Access-Control-Allow-Origin': "*",
         'Access-Control-Allow-Methods': 'POST, PUT, GET, OPTIONS',
         'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization'
      }
      }).then(function(response) { return response.json()})
      .catch(e => console.error(e.message))
      .then(function(json) {
        //setToken(json.access_token); 
        console.log(json)
        fetch(hhUrl+'/api/v2/'+clientId+'/Traversals', {
        method: 'POST',
        body: JSON.stringify({
          shortCode: shortCode,
        }),
        'headers': {
          'Authorization': 'Bearer ' + json.access_token,
          'Content-type': 'application/json; charset=UTF-8'
        }
        }).then(response => response.json())
        .then(json => {
          setQuestion(json);
          console.log("here", json);
        })
      })
      // After fetching data stored it in posts state.
      // setPosts(response.data);
      

      // Closed the loading page
  }

  // Call the function
  loadPost();

  }, [hhTokenURL, hhUrl, clientId, clientSecret]);
  console.log("answer", question);
  const saveCheckedAns = (checked, text, ansNodeId, ansId, quesId, questionCount, questionNumber) => {
    setError(false);
    let clearAnswer = question.nodes[questionNumber].questions[0].answers;
      for (var count in clearAnswer) {
        if (clearAnswer[count].controlType === "Radio") {
          setRadioAnswer([]);
          break; //Stop this loop, we found it!
        }
      }
    if(checked === true) {
      const items = {
        nodeId: ansNodeId,
        questionId: quesId,
        answerId: ansId,
        value: text
      };
      setCheckAnswer([...checkAnswer, items]);
    } else {
      const arr = checkAnswer.filter((item) => item.answerId !== ansId);
      setCheckAnswer(arr)
    }
  }

  const saveTextAns = (ansEntered, text, ansNodeId, ansId, quesId, questionCount, questionNumber) => {
    //setQuestionsCount(questionCount);
    setError(false);

    
    if(ansEntered !== "") {
      if(radioAnswer.length >= 1) {
      for (var i in radioAnswer) {
        if (radioAnswer[i].questionId === quesId) {
          radioAnswer[i].answerId = ansId;
          radioAnswer[i].value = ansEntered;
          setTextAnswer([radioAnswer]);
          break; //Stop this loop, we found it!
        } else {
          const items = {
            nodeId: ansNodeId,
            questionId: quesId,
            answerId: ansId,
            value: ansEntered
          };
          setTextAnswer([items]);
        }
      }
  } else {
    const items = {
      nodeId: ansNodeId,
      questionId: quesId,
      answerId: ansId,
      value: ansEntered
    };
    setTextAnswer([items]);
  }
    }
  }

  const saveRadioAnswer = (fieldType, ansText, nodeId, answerId, questionId, questionCount, questionNumber) => {
    //setQuestionsCount(questionCount);
    //if(checked === true) {
    // if(questionCount > 1) {
       
      let clearAnswer = question.nodes[questionNumber].questions[0].answers;
      for (var count in clearAnswer) {
        if (clearAnswer[count].controlType === "Checkbox") {
          var isAllCheck = false;
          var cbarray = document.getElementsByName(questionId);
          for(var iC = 0; iC < cbarray.length; iC++){
            cbarray[iC].checked = isAllCheck
            setCheckAnswer([]); 
          } 
          break; //Stop this loop, we found it!
        }
      }

      if(radioAnswer.length >= 1) {
          for (var i in radioAnswer) {
            if (radioAnswer[i].questionId === questionId) {
              radioAnswer[i].answerId = answerId;
              radioAnswer[i].value = ansText;
              setRadioAnswer(radioAnswer);
              break; //Stop this loop, we found it!
            } else {
              const items = {
                nodeId: nodeId,
                questionId: questionId,
                answerId: answerId,
                value: ansText
              };
              setRadioAnswer([...radioAnswer, items]);
            }
          }
      } else {
        const items = {
          nodeId: nodeId,
          questionId: questionId,
          answerId: answerId,
          value: ansText
        };
        setRadioAnswer([items]);
      }
  }

  const saveAnswer = (fieldType, ansText, nodeId, answerId, questionId, questionCount) => {
    setReadOnly(true);
      // Await make wait until that 
      // promise settles and return its result
      setError(false);
      let answerData = [...textAnswer, ...checkAnswer, ...radioAnswer];
      //answerData = answer;
    if(answerData.length === 0) {
      setError(true);
      setReadOnly(false);
    } else {
      fetch(hhTokenURL+'/connect/token', {
      method: 'POST',
      body: new URLSearchParams({
        grant_type: 'client_credentials',
        client_id: clientId,
        client_secret: clientSecret,
      }),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
      }).then(response => response.json())
      .then(json => {
        fetch(`${hhUrl}/api/v2/${clientId}/Traversals/${question.traversalId}/respond`, {
        method: 'POST',
        body: JSON.stringify(answerData),
        'headers': {
          'Authorization': 'Bearer ' + json.access_token,
          'Content-type': 'application/json; charset=UTF-8'
        }
        }).then(response => response.json())
        .then(json => {
          setQuestion(json);
          setReadOnly(false);
          setTextAnswer([]);
          setAnswer([]);
          setRadioAnswer([]);
          answerData = [];
          setCheckAnswer([]);
        })
      })
    }
  }
  
  if(question?.nodes?.length === 0) {

    const traversalId = question?.traversalId;
    
    fetch(hhTokenURL+'/connect/token', {
      method: 'POST',
      body: new URLSearchParams({
        grant_type: 'client_credentials',
        client_id: clientId,
        client_secret: clientSecret,
      }),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
      }).then(response => response.json())
      .then(json => {
        //setToken(json.access_token); 

        let user = "";
        if (typeof window !== "undefined") {
          var url_string = window.location.href;
          var url = new URL(url_string);
          user = url.searchParams.get("user");
        }
              
        fetch(`${hhUrl}/api/v2/${clientId}/Traversals/${traversalId}/summary`, {
        method: 'GET',
        'headers': {
          'Authorization': 'Bearer ' + json.access_token,
          'Content-type': 'application/json; charset=UTF-8'
        }
        }).then(response => response.json())
        .then(json => {
          let answerCollection = json.pertinentPositives;
          fetch('https://dzf2ufyxq9.execute-api.eu-west-2.amazonaws.com/dev/posthealthhero', {
          method: 'POST',
          body: JSON.stringify({
            user_id: user,
            user_details: 'user details',
            answer_json: answerCollection
          }),
          'headers': {
            'Content-type': 'application/json; charset=UTF-8'
          }
          }).then(response => response.json())
          .then(json => {
            console.log("json", json);
            navigate("submitted?id="+json.health_hero_id);
          })
        })
      })
  }
  
  return (
    <div className="App">
      <div>
      {question?.nodes?.map((node, i) => (
          <div key={node.nodeId}>
            {node?.questions.map((questions) => (
              <div key={questions.questionId}>
              <h2>
                <span dangerouslySetInnerHTML={{__html: questions.displayText}} />
                {/* <span>{questions.displayText}</span> */}
              </h2>
              {error && <span style={{color: "red"}}>Please enter the value</span>}
              {questions?.answers.map(answerOptions => (
                <div key={answerOptions.answerId} className="ansOptions">
                  {(answerOptions.controlType === "Number" || answerOptions.controlType === "Text") &&
                  <>
                  <input 
                    type={answerOptions.controlType}
                    defaultValue=""
                    className="submitAnsInput"
                    placeholder={answerOptions.displayText}
                    onChange={(e) => saveTextAns(e.target.value, answerOptions.displayText, answerOptions.nodeId, answerOptions.answerId, answerOptions.questionId, question?.nodes?.length, i)}
                   // onChange={(e)=>setTextAnswer(e.target.value)}  
                  />
                  </>
                  }
                  {answerOptions.controlType === "Date" &&
                  <>
                  <input 
                    type={answerOptions.controlType}
                    defaultValue=""
                    className="submitAnsInput"
                    placeholder={answerOptions.displayText}
                    onChange={(e) => saveTextAns(e.target.value, answerOptions.displayText, answerOptions.nodeId, answerOptions.answerId, answerOptions.questionId, question?.nodes?.length)}
                   // onChange={(e)=>setTextAnswer(e.target.value)}  
                  />
                  </>
                  }
                  {answerOptions.controlType === "Radio" &&
                  <>
                    <input 
                      type={answerOptions.controlType}
                      name={answerOptions.questionId}
                      disabled={readOnly}
                      onClick={(e) => saveRadioAnswer(e.target.checked, answerOptions.displayText, answerOptions.nodeId, answerOptions.answerId, answerOptions.questionId, question?.nodes?.length, i)}  
                    />
                    <label>{answerOptions.displayText}</label>
                  </>
                  }
                  {answerOptions.controlType === "Checkbox" &&
                  <>
                    <input 
                      type={answerOptions.controlType} 
                      name={answerOptions.questionId}
                      disabled={readOnly}
                      onClick={(e) => saveCheckedAns(e.target.checked, answerOptions.displayText, answerOptions.nodeId, answerOptions.answerId, answerOptions.questionId, question?.nodes?.length, i)}
                      //onClick={(e) => saveAnswer(answerOptions.displayText, answerOptions.nodeId, answerOptions.answerId, answerOptions.questionId)}  
                    />
                    <label>{answerOptions.displayText}</label>
                  </>
                  }
                </div>
              ))
              }
            </div>
            ))} 
            
          </div>
        ))}
        <>
        {question?.nodes?.length > 0 ?
                 <input 
                    type="button"
                    value="Submit"
                    className="submitAns"
                    disabled={readOnly}
                    onClick={(e) => saveAnswer(answer)}
                  />
                  :
                  <span>Loading...</span>
        }
                {/* {questions.answers[0].controlType === "Checkbox" &&
                  <input 
                    type="button"
                    value="Submit"
                    onClick={(e) => saveAnswer("Checkbox", answer, question?.nodes?.length)}
                  />
                }
                {(questions.answers[0].controlType === "Number" || questions.answers[0].controlType === "Text") &&
                  <input 
                    type="button"
                    value="Submit"
                    onClick={(e) => saveAnswer("Number", textAnswer, question?.nodes?.length)}
                  />
                } */}
              </>
      </div>
    </div>
  );
}

export default Form;
