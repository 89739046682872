let id = "";
      if (typeof window !== "undefined") {
        var url_string = window.location.href;
        var url = new URL(url_string);
        id = url.searchParams.get("id");
      }
const Submitted = () => {
  return <h1>Submitted {id}</h1>;
};

export default Submitted;