import { useState, useEffect } from 'react';
export default function Review() {
  const [answer, setAnswer] = useState([]);

  useEffect(() => {
    const loadPost = async () => {

      let formId = "";
      let user = "";
      if (typeof window !== "undefined") {
        var url_string = window.location.href;
        var url = new URL(url_string);
        formId = url.searchParams.get("fid");
        user = url.searchParams.get("user");
      }
      fetch(`https://dzf2ufyxq9.execute-api.eu-west-2.amazonaws.com/dev/form/${formId}/user/${user}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
      }).then(response => response.json())
      .then(json => { setAnswer(json.questions_meta) })
    }
    loadPost();
  }, []);
  return (
      <div>
      {answer?.map((row, i) => (
        <ul key={i}>
          <li>
            <p>{row.label}</p>
            <p>Answers:</p>
            <ul>
              {row?.options.map((answers) => (
                <li key={answers.key}>{answers.text}</li>
              ))}
            </ul>
          </li>
        </ul>
      ))}
      </div>
  )
}